* {
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  max-width: 100%;
  min-height: 100vh;
  font-family: 'Work Sans', sans-serif;
  align-items: center;
  justify-content: space-between;
}

#canvas {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  inset: 0;
}

canvas {
  display: block;
}

.hero {
  text-transform: uppercase;
}

h1 {
  font-size: 6vw;
  font-weight: 200;
  font-style: italic;
  text-align: center;
  letter-spacing: 4vw;
  opacity: 0.9;
  user-select: none;
  /* mix-blend-mode: multiply; */
}

/* main {} */

footer {
  margin-bottom: 0;
}

#palette {
  opacity: 0.1;
}

#palette:focus {
  opacity: 0.7;
  outline: none;
}
