* {
  margin: 0;
  padding: 0;
}

body {
  max-width: 100%;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: Work Sans, sans-serif;
  display: flex;
  overflow: hidden;
}

#canvas {
  z-index: -1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
}

canvas {
  display: block;
}

.hero {
  text-transform: uppercase;
}

h1 {
  text-align: center;
  letter-spacing: 4vw;
  opacity: .9;
  user-select: none;
  font-size: 6vw;
  font-style: italic;
  font-weight: 200;
}

footer {
  margin-bottom: 0;
}

#palette {
  opacity: .1;
}

#palette:focus {
  opacity: .7;
  outline: none;
}

/*# sourceMappingURL=index.3a86dbaa.css.map */
